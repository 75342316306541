<template>
    <router-view />
</template>

<script>
// @ is an alias to /src
import HrHeader from "@/components/HrHeader";
import HrSidebar from "@/components/HrSidebar";

export default {
  name: "App",
  components: {
    HrHeader,
    HrSidebar,
  },
};
</script>

<style>
</style>
